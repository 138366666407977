<template>
    <section>
        <h2>Privacybeleid en klachtenregeling</h2>
        <p>Op Het Lint Orthopedagogiek hecht belang aan de bescherming van
            persoonsgegevens. De richtlijnen zoals opgesteld binnen de Algemene
            Verordening Gegevensbescherming (AVG) worden gevolgd. Ik vind het
        belangrijk om zorgvuldig om met uw persoonsgegevens om te gaan.</p>
        <p>Om welke persoonsgegevens gaat het?</p>
        <p>Wanneer u contact opneemt met Op Het Lint Orthopedagogiek, voor hulp voor u als
           ouder(s) en/ of voor uw kind, dan vragen wij u om uw persoonsgegevens.
       Deze persoonsgegevens zijn:</p>
       <ul>
        <li>Voor- en achternaam van ouder(s) en kind(eren)</li>
        <li>Geboortedatum</li>
        <li>Geslacht</li>
        <li>Gezinssituatie (overige gezinsleden, gezag en relatie
        ouders)</li>
        <li>Woonadres</li>
        <li>Telefoonnummer(s)</li>
        <li>E-mailadres</li>
    </ul>
    <p>Persoonsgegevens worden met de volgende doeleinden verwerkt:</p>
    <ul>
        <li>Het uitvoeren van de behandelovereenkomst en de declaratie
        van de verrichte werkzaamheden</li>
        <li>Het onderhouden van contact gedurende de behandeling.</li>
    </ul>
    <h3>Verstrekken aan derden</h3>
    <p>(Persoons)gegevens die u aan mij verstrekt worden niet gedeeld
       met derden, mits u daar nadrukkelijk toestemming voor heeft gegeven.
       Door middel van een toestemmingsformulier vraag ik u bij aanvang
       van de hulp voor toestemming om persoonsgegevens te delen met
       bijvoorbeeld de school van uw kind en eerdere/ andere betrokken
   hulpverleners.</p>
   <p>Wanneer er sprake is van vergoede zorg, loopt dit via de twee
       gevestigde praktijken OC Leidsche Rijn/ Rondom Leren. Zij houden
       zich ook aan de AVG en u kunt op hun website lezen, hoe de
   bescherming bij hen geregeld is.</p>
   <h3>Bewaartermijn persoonsgegevens</h3>
   <p>Op het Lint Orthopedagogiek bewaart persoonsgegevens in een
       dossier op naam van u of uw kind gedurende de looptijd van de
       behandeling. Daarna wordt uw dossier voor de duur van 15 jaar
       bewaard in het archief. Dit is wettelijk verplicht volgens de Wet op
   de Geneeskundige Behandelingsovereenkomst (WGBO) en de Jeugdwet (Jw).</p>
   <h3>Welke rechten heeft u omtrent uw persoonsgegevens</h3>
   <p>U heeft recht op:</p>
   <ul>
    <li>Informatie: u kunt bij ons navragen of en hoe uw
    persoonsgegevens worden verwerkt</li>
    <li>Inzage: u heeft de mogelijkheid tot inzage in uw dossier</li>
    <li>Rectificatie: als gegevens in het dossier niet kloppen, dan
    kunt u ons verzoeken dit te corrigeren</li>
    <li>Vergeten te worden: wanneer u toestemming heeft gegeven om
       uw gegevens te verwerken, heeft u eveneens het recht om deze
   te laten verwijderen</li>
   <li>Bezwaar: u heeft het recht bezwaar te maken tegen de
   verwerking van uw persoonsgegevens</li>
   <li>Overdracht van gegevens: u kunt ons verzoeken uw gegevens
   over te dragen aan een andere organisatie</li>
</ul>
<h3>Vragen en klachten</h3>
<p>Als u nog vragen heeft of klacht heeft ten aanzien van ons
    privacybeleid, dan kunt u contact opnemen met ons via info@ophetlint.nl.
    Een klacht probeer ik naar tevredenheid op te lossen. Mocht dit
onverhoopt niet lukken, dan kunt u zich wenden tot de Autoriteit Persoonsgegevens (AP).</p>
<h3>Klachtenregeling</h3>
<p>Wanneer u onverhoopt niet tevreden over de behandeling/
    begeleiding dan kunt u altijd direct met mij contact opnemen.
    U mag van mij een luisterend oor verwachten en ik zal mij inzetten
    om tot een oplossing te komen. (Wanneer u on tevreden blijft,
kunt u contact opnemen met de onafhankelijke klachtenregeling van het NVO.)</p>
<h3>Nummers/registraties</h3>
<table>
    <thead>
      <tr>
        <td>Telefoon</td>
        <td><a href="tel:06-42648564">06-42648564</a> (bellen en Whatsapp)</td>
        <td></td>
    </tr>
</thead>
<tbody>
  <tr>
    <td>E-mail</td>
    <td><a href="mailto:info@ophetlint.nl">info@ophetlint.nl</a></td>
    <td></td>
</tr>
<tr>
    <td>NVO registratie</td>
    <td>21100</td>
    <td></td>
</tr>
<tr>
    <td>SKJ registratie</td>
    <td>120013661</td>
    <td></td>
</tr>
<tr>
    <td>KvK-nummer</td>
    <td>80095305</td>
    <td></td>
</tr>
<tr>
    <td>IBAN</td>
    <td>NL33KNAB0501593586</td>
    <td></td>
</tr>
<tr>
    <td>BIC</td>
    <td>KNABNL2H</td>
    <td></td>
</tr>
<tr>
    <td>BTW</td>
    <td>NL003393490B83</td>
    <td></td>
</tr>
</tbody>
</table>
</section>
</template>
<style scoped lang="scss">
section {
  text-align:left;
  padding: 10px;
}
</style>
